import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const OnlineShopArea = () => {
    return (
        <>
            {/* ===================== Service Details Area start ===================== */}
            <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                        <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/man-loading-container-with-boxes-2021-09-24-02-51-32-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/friends-shopping-shoes-online-2021-08-26-15-34-42-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Имате нужда от помощ при разработката на онлайн магазин?
                                        </h4>
                                        <p>
                                        Понастоящем електронната търговия е процъфтяващ бизнес. 
                                        Броят на хората, които искат да пазаруват, без да напускат компютъра и дома си, непрекъснато расте.
                                        Това е ефективно средство за привличане на нови клиенти и партньори не само от вашия град, но и от други градове и региони чрез използването на Интернет. 
                                        Основната цел на електронния магазин е да демонстрира и продава предлаганите продукти и услуги. 
                                        Въпреки някои прилики с каталога в електронен вид, обхватът на неговите възможности е много по-широк.
                                            
                                        </p>
                                        <h4> Защо е важно да продавате онлайн?</h4>
                                        <p>
                                             1. Работи 24/7 <br />
                                             2. Предлагате продуктите си навсякъде <br />
                                             3. Нулеви разходи за търговски площи <br />
                                             4. Промотирате физическия си бизнес <br />
                                             5. Поддържате постоянна връзка с редовните си клиенти <br />
                                             6. Нови възможности за маркетинг <br />
                                             7. Работите без ограничения <br />
                                             8. Възможност за пълна техническа поддръжка от професионалисти <br />
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Разработка на адаптивен дизайн, който е напълно скалируем с всички смарт устройства за удобството на Вашите клиенти.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Колко време отнема да завършим даден проект?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Въз основа на списъка с функции и вида на компанията се  
                                                        определя времето, необходимо за разработване на 
                                                        е-магазина. В зависимост от техническото задание това може да се постигне за 2 седмици или за 2 месеца.
                                                        Това ще включва техническа архитектура, 
                                                        кодиране на потребителския интерфейс, настройка на бекенда, 
                                                        функционално изпълнение, интеграция и тестване.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва разработката на мобилното приложение?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Цената се определя спрямо време и ресурси!
                                                        Това е най-често използваният подход, при разработката на софтуер, особено ако става
                                                        въпрос за големи и сложни проекти. Специалистите определят цената, на база времето, което ще им 
                                                        отнеме за разработката на всеки един компонент и функционалност на апликацията.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default OnlineShopArea;
