let serviceList = [
  {
    title: "Изработка на мобилни приложения",
    des: "Цялостни решения в областта на изработката на мобилни приложения.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Специализиран софтуер",
    des: "Бизнеса разчита на решенията ни, за да се възползват от днешните възможности и да управляват стратегията за бизнес растеж.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Изработка на онлайн магазин",
    des: "Работата по успешни проекти ни е наложила, като водеща компания в изграждането и оптимизацията на онлайн магазини",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Корпоративен уеб дизайн",
    des: "Ще създадем уеб сайт и онлайн презентация на вашата компания, с които ще се гордеете и подобрите присъствието си в интернет.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "SEO оптимизация",
    des: "Процес, който включва различни подходи, с помощта на които вашият уеб сайт да се класира на челни позиции в Google и др.",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "Техническа подръжка",
    des: "Всеки уеб сайт има нужда от поддръжка, просто не всеки бизнес осъзнава нуждата от необходимостта ѝ.",
    img: "assets/img/service-icon/3.png",
  },
];

export default serviceList;
