import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import Breadcrumb from "../components/Breadcrumb";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import WorkProcessTwo from "../components/WorkProcessTwo";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"За нас"} />

      {/* About Area One */}
      <AboutAreaTwo />

      {/* FAQ Area One */}
      <FaqAreaTwo />

      {/* Counter Area One */}
      <CounterAreaTwo />

      {/* Work Process One */}
      <WorkProcessTwo />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default About;
