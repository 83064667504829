import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import SupportArea from "../components/SupportArea";

const Support = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Техническа поддръжка"} />

      {/* Support Area */}
      <SupportArea />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default Support;
