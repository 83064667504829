import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const SupportArea = () => {
    return (
        <>
            {/* ===================== Service Details Area start ===================== */}
            <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                        <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/smiling-male-broker-in-headset-trades-on-stock-exc-2022-12-16-21-43-40-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/asian-it-engineer-providing-technical-support-2021-09-24-03-44-49-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Поддръжка на уеб сайт
                                        </h4>
                                        <p>
                                        Техническа поддръжка на уеб сайт е дейност, която осигурява функционално работещ уеб сайт и хостинг сървър, 
                                        под различните браузъри и устройства (мобилни телефони, таблети, лаптопи, десктоп компютри с малки и големи монитори и др.).
                                        
                                            
                                        </p>
                                        <h4> Защо е важно да правите мобилни приложения?</h4>
                                        <p>
                                        Често се налага да поддържаме уеб сайтове, изработени от други интернет агенции и фирми. 
                                        Това е напълно нормална практика и в повечето случаи успяваме да осъществим на 100% изискванията на клиентите на приемлива цена. 
                                        Има изключения, когато е трудно да осъществим изискванията на нормална цена и тогава клиента трябва да вземе 
                                        решение дали да продължи да използва стария си сайт „без (голяма) промяна във функционалността“ или да вземе 
                                        решение за изработка на нов сайт, който ще му позволи да има всички функционалности на нормална цена 
                                        (вкл. бъдещи секции/функционалности като допълнения към сайта).
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                         1. Хостинг сървър <br />
                                                         2. Отстраняване на Malware <br />
                                                         3. Обновяване на PHP версиите <br />
                                                         4. Изследване и подобрения за бързо зареждане на уеб сайт <br />
                                                         5. Поддръжка на WordPress (Уърдпрес) сайтове
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Инвестиция в поддръжка на уеб сайт?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Поддръжка на уеб сайт е бизнес инвестиция! 
                                                    За всяка бизнес инвестиция е важно да се следи възвръщаемостта на инвестицията (ROI) и реализираната печалба. 
                                                    Процесът обаче не е толкова прост, тъй като цялата инвестиция за изработка на уеб сайт, 
                                                    хостинг и поддръжка на сайт, популяризация чрез реклама и SEO оптимизация на сайт е една голяма инвестиция. 
                                                    Инвестицията трябва да бъде (бързо) възвърната, като доведе нови клиенти и стимулира настоящите клиенти към повече поръчки, 
                                                    което в крайна сметка да доведе до реализиране на печалба. Това е нормалната цел на всеки един уеб сайт или онлайн магазин.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва поддръжката на сайт?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Цената за поддръжка на сайтове е строго индивидуална и зависи от функционалността на сайта, 
                                                    нужните операции и компетенции за качествена поддръжка. 
                                                    Предоставяме отлични цени за поддръжка на изработени от нас сайтове, 
                                                    тъй като знаем какво правим и поддръжката на изработени от нас сайтове отнема в пъти по-малко време, 
                                                    в сравнение с уеб сайтове, изработени от произволни фирми и интернет агенции.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default SupportArea;
