import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileDevArea = () => {
    return (
        <>
            {/* ===================== Service Details Area start ===================== */}
            <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                    <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/developing-mobile-application-2021-09-17-18-19-09-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/designer-planning-to-work-together-draw-applicatio-2022-12-14-05-34-08-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Имате нужда от помощ при разработката на мобилно приложение?
                                        </h4>
                                        <p>
                                            Броят на мобилните приложения се увеличава от дълго време. Тази част от разработката на софтуер е свързана със създаването на приложения, които могат да се използват на мобилни устройства.
                                            Различните операционни системи, като iOS и Android, се нуждаят от различни видове приложения. Приложенията предлагат по-добро потребителско изживяване от мобилното сърфиране в интернет, тъй като използват вградените функции на телефона.
                                            Съществуват много различни приложения, такива за музика, игри, комуникация, календари и др. Сигурни сме, че сте чували за Spotify,  WhatsApp, Instagram и Facebook - едни от най-използваните приложения в световен мащаб.
                                            Нека да разгледаме как работи разработването на мобилни приложения. Изграждането на мобилно приложение, за да получите част от милиардния пазар, може да звучи като чудесен начин за печелене на пари, но решението трябва да бъде добре обмислено и планирано.
                                            Изработването на приложение е добър бизнес ход. Но трябва да знаете как да направите мобилно приложение.
                                            
                                        </p>
                                        <h4> Защо е важно да правите мобилни приложения?</h4>
                                        <p>
                                             Тъй като смартфоните и другите интелигентни устройства 
                                             непрекъснато се увеличават, повече хора инсталират и изтеглят 
                                             приложения от всякога. Днес мобилните приложения са 
                                             жизнената сила на повечето предприятия, тъй като привличат 
                                             повече клиенти. Statista твърди, че общата продажба на 
                                             мобилни приложения ще достигне до 613 млрд. щатски долара през следващите три години.
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Разработка на нейтив Android и iOS приложения както и хибридни продукти подходящи и оптимизирани за двете операционни системи.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Колко време отнема да завършим даден проект?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Въз основа на списъка с функции и вида на компанията се  
                                                        определя времето, необходимо за разработване на 
                                                        приложението. Ще трябва да изградите мобилното 
                                                        приложение, а също и бекенда за мобилното приложение. 
                                                        Всички разработки: приложението за iOS, приложението за 
                                                        Android и бекенда трябва да се извършват паралелно. За по-
                                                        малката версия това може да се постигне за 2 месеца, за 
                                                        средно голямо приложение може да са необходими около 3-3,5 
                                                        месеца, докато за голямо приложение може да са необходими 
                                                        около 5-6 месеца. Това ще включва техническа архитектура, 
                                                        кодиране на потребителския интерфейс, настройка на бекенда, 
                                                        функционално изпълнение, интеграция и тестване.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва разработката на мобилното приложение?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Цената се определя спрямо време и ресурси!
                                                        Това е най-често използваният подход, при разработката на мобилни приложения, особено ако става
                                                        въпрос за големи и сложни проекти. Специалистите определят цената, на база времето, което ще им 
                                                        отнеме за разработката на всеки един компонент и функционалност на апликацията.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default MobileDevArea;
