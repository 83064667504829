import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";



const FooterTwo = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  
  useEffect(() => {
    setEmail(sessionStorage.getItem("email") || '');

    window.scrollTo(0,0)
  },[]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handleButtonClick = () => {
    // Save the email in session storage
    sessionStorage.setItem("email", email);

    // Redirect to the contact page

    navigate("/contact");
  }
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/si-logo-dark.png" alt="img" />
                </div>
                <div className="details">
                  <p>Имате нужда от уеб сайт или софтуер по поръчка?</p>
                  {/* ============== Вържи Емайла =============*/}
                  <div className="subscribe mt-4">
                    <input
                      type="text"
                      placeholder="E-mail"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <button onClick={handleButtonClick}>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* ============== Вържи Услугите =============*/}
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Услуги</h4>
                <ul>
                  <li>
                    <Link to="/webdesign">
                      <FaChevronRight /> Уеб Дизайн
                    </Link>
                  </li>
                  <li>
                    <Link to="/customsoftware">
                      <FaChevronRight /> Изработка на софтуер
                    </Link>
                  </li>

                  <li>
                    <Link to="/mobiledev">
                      <FaChevronRight /> Мобилни приложения
                    </Link>
                  </li>
                  <li>
                    <Link to="/seo">
                      <FaChevronRight /> SEO оптимизация
                    </Link>
                  </li>
                  <li>
                    <Link to="/support">
                      <FaChevronRight /> Поддръжка и оптимизация
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Полезно</h4>
                <ul>
                  <li>
                    <Link to="/about">
                      <FaChevronRight /> За нас
                    </Link>
                  </li>
                  <li>
                    <Link to="/case-study-details">
                      <FaChevronRight /> Нашите проекти
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Цени
                    </Link>
                  </li>
                  <li>
                    <Link to="/team">
                      <FaChevronRight /> Екип
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaChevronRight /> Контакти
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Контакти</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li>
                      <FaMapMarkedAlt /> <a href="https://www.google.com/maps/place/Stara+Zagora+Centre,+ul.+%22Graf+Ignatiev%22+28,+6000+Stara+Zagora/@42.428222,25.6321047,17z/data=!3m1!4b1!4m5!3m4!1s0x40a8699f8a90592b:0x95c25ed71af3fb69!8m2!3d42.4282181!4d25.6346796?entry=ttu" target="_blank">
                      Стара Загора, ул. Граф Игнатиев 28
                      </a>
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt /> <a href="tel:(+359) 877 47 57 75">(+359) 877 47 57 75</a>
                    </li>
                    <li className="mt-2">
                      <FaEnvelope /> <a href="mailto:office@siweb.bg">office@siweb.bg</a>
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a href="https://www.facebook.com/simplyimpressivewebstudio/" target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/siweb-bg/" target="_blank">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© Simply Impressive Web Studio 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="#">Общи условия</a>
                <a href="#">Политика за личните данни</a>
                <a href="/contact">Контакти</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
