import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import CustomSoftwareArea from "../components/CustomSoftwareArea";

const CustomSoftware = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Разработка на специализиран софтуер"} />

      {/* Custom Software Area */}
      <CustomSoftwareArea />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default CustomSoftware;
