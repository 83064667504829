import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const WebDesignArea = () => {
    return (
        <>
            {/* ===================== Service Details Area start ===================== */}
            <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                        <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/web-design-software-2021-08-26-22-39-51-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/web-designers-working-at-the-office-2021-09-17-18-15-50-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Имате нужда от помощ при разработката на уеб дизайн?
                                        </h4>
                                        <p>
                                            При създаването на дизайн работата се извършва от уеб дизайнер, който прави оформлението на сайта. 
                                            То включва лого, цветова палитра, шрифтове, пиктограми и други графични елементи. 
                                            Основната цел е да има ясна структура на проекта, да се визуализира как ще изглеждат страниците и съдържанието, 
                                            както и да има точна представа за крайния готов уеб сайт.

                                            В дизайна на сайта трябва да бъде фиксирано и точното място на текстовете. 
                                            Оптималният вариант за създаване на качествено текстово съдържание за един нов уеб сайт включва две стъпки: 
                                            да се планира от SEO специалист и след това да се разпише от копирайтър.
                                            
                                        </p>
                                        <h4> Защо е важно да изберете респонсив и скалируем дизайн?</h4>
                                        <p>
                                        1. Ако искаме Google да е щедър и приятелски настроен към нас, вслушвайте се в препоръките му. А респонсив дизайн е една от тези препоръки! <br />
                                        2. Спестява ви време, ресурси и пари <br />
                                        3. По-лесно управление на сайта <br />
                                        4. Респонсив дизайна е способен да ви донесе повече лийдове <br />
                                        5. Респонсив дизайн е модерното решение
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Стартирате НОВ бизнес или ще обновявате стария сайт? 
                                                    Можем да помогнем с направа на сайт, такъв, какъвто го искате. 
                                                    Предлагаме Премиум уеб дизайн с внимание към детайла и грижливо отношение към клиента.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Колко време отнема да завършим даден проект?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Срока за изработка не е константа величина, макар за нас да е отправна точка при стартирането на проекта.
                                                    Времето за изработка варира според сложността на желаният уеб дизайн, формиране на структура, логика и програмиране.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва разработката на мобилното приложение?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Цената се определя спрямо време и ресурси!
                                                        Това е най-често използваният подход, при разработката софтуер, особено ако става
                                                        въпрос за големи и сложни проекти. Специалистите определят цената, на база времето, което ще им 
                                                        отнеме за разработката на всеки един компонент и функционалност на апликацията.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default WebDesignArea;
