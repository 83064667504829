import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>За Нас</h6>
                <h2 className='title'>
                  Уеб дизайн и софтуерни решения
                </h2>
                <p className='content mb-4'>
                Това, което обичаме ни се превърна в работа. Ние нямаме нито един работен ден, защото обичаме да правим красиви и функционални проекти.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Изследване и планиране
                      </li>
                      <li>
                        <FaCheckCircle /> Проектиране
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Изпълнение
                      </li>
                      <li>
                        <FaCheckCircle /> Внедряване и използване
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                В основата на всеки софтуерен проект лежи подбран за целта софтуерен процес,
                който определя всички извършвани дейности, ролите на участниците в проекта,
                както и междинните и крайните резултати, които се очакват от изпълнението на проекта.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
