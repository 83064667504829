import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>Нашите процеси</h6>
            <h2 className='title'>
              Основни принципи за <span>успешна</span> софтуерна разработка
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/9.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Стъпка 1</p>
                  <h5 className='mb-3'>Планиране и анализ</h5>
                  <p className='content'>
                    
                      Това е процесът по уточняване всички особености и изисквания към крайния продукт. 
                      Разбира се, планирането и анализа, не случайно се случват в самото начало на създаване на софтуера.
                      На този етап трябва да се уточни цялата логика на проекта, заедно с неговите функционалности

                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/10.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Стъпка 2</p>
                  <h5 className='mb-3'>Дизайн и разработка</h5>
                  <p className='content'>
                  Създаването на дизайн е ключов момент, който включва различни аспекти и изисква сериозна инвестиция от време и работа, 
                  именно заради огромното си значение. 
                  В зависимост от модела на разработка. софтуерният продукт може да се развива и променя.

                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/11.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Стъпка 3</p>
                  <h5 className='mb-3'>Тест</h5>
                  <p className='content'>
                    След като имаме функциониращ софтуерен продукт, следва той да бъде тестван. 
                    На този етап се откриват грешки, бъгове, недостатъци и потенциални проблеми, 
                    пред които е изправен продукта. Провеждането на пълен тест е точно толкова важно, колкото са и останалите етапи.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/12.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Стъпка 4</p>
                  <h5 className='mb-3'>Имплементация</h5>
                  <p className='content'>
                  Имплементирането на софтуерния продукт може да бъде сложен процес, тъй като той трябва да бъде интегриран с различни системи, чрез
                  API's, а потребителите ще трябва да свикнат с тяхното използване, 
                  а понякога и с използването на съвсем нови технологии.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
