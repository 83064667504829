import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import MobileDevArea from "../components/MobileDevArea";

const MobileDev = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Разработка на мобилни приложения"} />

      {/* Mobile Dev Area */}
      <MobileDevArea />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default MobileDev;
