import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyAreaTwo from "../components/CaseStudyAreaTwo";
import FooterTwo from "../components/FooterTwo"; 
import NavBarTwo from "../components/NavbarTwo";

const CaseStudyDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Нашите проекти"} />

      {/* Case Study Area */}
      <CaseStudyAreaTwo />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default CaseStudyDetails;
