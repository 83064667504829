import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const SEOArea = () => {
    return (
        <>
             {/* ===================== Service Details Area start ===================== */}
             <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                        <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/seo-search-engine-optimization-2022-11-01-00-05-21-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/web-marketing-managers-with-gadgets-working-on-pai-2022-12-16-07-46-38-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Какво е SEO оптимизация?
                                        </h4>
                                        <p>
                                        SEO оптимизация (Search Engine Optimization) е целенасочен процес, който включва различни стратегия и тактики, с помощта на които вашият уеб сайт да се класира на челни позиции в резултатите от търсенето в Google и други търсачки. <br />
                                        Ако оставим настрана дългите определения, най-важното, което собствениците на уеб сайтове и онлайн магазини трябва да знаят за SEO оптимизацията е, че тя е една от най-ефективните онлайн маркетингови стратегии. 
                                        Причините? Професионалната оптимизация на вашия уеб сайт ще ви помогне да подобрите онлайн присъствието на бизнеса ви, да привлечете повече качествен трафик към сайта, да ангажирате нови целеви клиенти и да спечелите повече реализации и приходи. 
                                        Всичко това превръща SEO оптимизацията в ключово направление, към което редица бизнеси – малки и големи – вече са насочили своите усилия.
                                            
                                        </p>
                                        <h4> Защо SEO оптимизацията е важна за вашия онлайн бизнес?</h4>
                                        <p>
                                        За да останат конкурентоспособни в съвременните условия, успешните компании работят върху израждане на силно онлайн присъствие, 
                                        за да бъдат по-видими, полезни и достъпни за своята целева аудитория. 
                                        Как може да постигнете всичко това? Като се фокусирате върху създаване и следването на SEO стратегия за вашия сайт.
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        1. Технически анализ на сайта <br />
                                                        2. Анализ на съдържанието и копирайтинг услуги <br />
                                                        3. Линк билдинг услуги <br />
                                                        4. Локална SEO оптимизация <br />
                                                        5. UX Анализ <br />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Предимства да инвестирате в SEO?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        1. Помага ви да привлечете повече клиенти <br />
                                                        2. Изгражда доверие <br />
                                                        3. Разширява разпознаваемостта на вашия бранд <br />
                                                        4. Минимални разходи за реклама <br />
                                                        5. Реално измерими единици, които може да следите всеки момент <br />
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва SEO оптимизация?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Свържете се с нашия екип, за да изготвим индивидуална оферта за нуждите на вашия бизнес.
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default SEOArea;
