import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import OnlineShopArea  from "../components/OnlineShopArea";

const OnlineShop = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Разработка на онлайн магазин"} />

      {/* Online Shop Area */}
      <OnlineShopArea />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default OnlineShop;
