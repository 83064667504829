import React from "react";
import { Link } from "react-router-dom";

const CaseStudyAreaTwo = () => {
  return (
    <>
      {/*================== case-study area start ==================*/}
      <div className='case-study-area pd-top-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              <div className='section-title text-center'>
                <h6 className='sub-title-sky-blue'>Нашите проекти</h6>
                <h2 className='title'>Докоснете се до част от нашата работа</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 col-md-6'>
              <div className='single-case-study-inner style-2'>
                <div className='thumb'>
                  <div className="beroe">
                  <img src='assets/img/gallery/Beroe.png' alt='img' />
                </div>
                </div>
                <div className='details'>
                  <h5>
                    <Link to='https://beroe.bg/' >Изработка на уебсайт на ПФК Берое</Link>
                  </h5>
                  <a className='cat' href='https://beroe.bg/'Visit PFC Beroe Website>
                  </a>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='single-case-study-inner style-2'>
                    <div className='thumb'>
                      <img src='assets/img/gallery/SportCity.png' alt='img' />
                    </div>
                    <div className='details'>
                      <h5>
                        <Link to='https://sportcityshop.com/'>Онлайн магазин SportCityShop</Link>
                      </h5>
                      <a className='cat' href='https://sportcityshop.com/'>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='single-case-study-inner style-2'>
                    <div className='thumb'>
                      <img src='assets/img/gallery/Logistik.png' alt='img' />
                    </div>
                    <div className='details'>
                      <h5>
                        <Link to='https://logistik.bg/'>B2B платформа Логистик БГ</Link>
                      </h5>
                      <a className='cat' href='https://logistik.bg/'>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-case-study-inner style-2'>
                <div className='thumb'>
                  <img src='assets/img/gallery/8hristo.com_-1.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='https://8hristo.com/'>Официален уеб сайт на Христо Стоичков</Link>
                  </h5>
                  <a className='cat' href='https://8hristo.com/'>
                  </a>
                </div>
              </div>
              <div className='single-case-study-inner style-2'>
                <div className='thumb'>
                  <img src='assets/img/gallery/takevent2.png' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='https://takevent.com/'>Онлайн платформа за продажба на билети ТейкИвент</Link>
                  </h5>
                  <a className='cat' href='https://takevent.com/'>
                  </a>
                </div>
              </div>
              <div className='single-case-study-inner style-2'>
                <div className='thumb'>
                  <img src='assets/img/gallery/tickets.circus-balkanski.eu_.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    <Link to='https://tickets.circus-balkanski.eu/'>Уеб сайт и тикет система на Цирк Балкански</Link>
                  </h5>
                  <a className='cat' href='https://tickets.circus-balkanski.eu/'>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyAreaTwo;
