import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaGroup = () => {
  return (
    <>
      {/* ================= TeamAreaGroup Start =================*/}
      <div className='team-area bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                    Станислав Иванов
                  </h5>
                  <p>CEO</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/Преслав.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                  Преслав Динев
                  </h5>
                  <p>Senior Full Stack Engineer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/Преслав.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                  Славин Славов
                  </h5>
                  <p>UI/UX Designer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/Митака.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                  Димитър Сергеев
                  </h5>
                  <p>Front-End Developer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/Митака.jpg' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                  Йордан Узунов
                  </h5>
                  <p>Front-End Developer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/3.png' alt='img' />
                </div>
                <div className='details'>
                  <h5>
                  Антон Танев
                  </h5>
                  <p>Back-End Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================= TeamAreaGroup End =================*/}
    </>
  );
};

export default TeamAreaGroup;
