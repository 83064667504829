import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import NavBarTwo from "../components/NavbarTwo";
import WebDesignArea  from "../components/WebDesignArea";

const WebDesign = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Разработка на уеб дизайн"} />

      {/* Web Design Area */}
      <WebDesignArea />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default WebDesign;
