import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const FaqAreaTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className='faq-area pd-bottom-120'>
        <div className='container'>
          <div className='row pd-top-120'>
            <div className='col-xl-7 col-lg-6 col-md-8 order-lg-last'>
              <div className='about-thumb-inner video-thumb'>
                <div className="ratio ratio-16x9">
                  <iframe src="https://www.youtube.com/embed/HGSR3FDVkkQ" title="YouTube video" allowFullScreen></iframe>
                </div>
                {/* <img
                  className='main-img'
                  src='assets/img/about/15.png'
                  alt='img'
                />
                <span
                  onClick={() => setOpen(true)}
                  className='video-play-btn-hover'
                >
                  <img src='assets/img/video.svg' alt='img' />{" "}
                  <h6 className='d-inline-block'>Как работим ние?</h6>
                </span>
                <ModalVideo
                  channel='youtube'
                  autoplay
                  isOpen={isOpen}
                  videoId='HGSR3FDVkkQ'
                  onClose={() => setOpen(false)}
                /> */}
              </div>
            </div>
            <div className='col-xl-5 col-lg-6 align-self-center'>
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h2 className='title'>
                  Имате <span>въпроси</span> към нас?
                </h2>
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Какви услуги предлагаме?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Ние сме компания, специализирана в разработката и поддръжката на персонализиран софтуер за различни индустрии и бизнес сектори.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Колко опитен е нашият екип?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Нашият екип от опитни софтуерни инженери и разработчици със завиден стаж от над 10 години е готов да
                      предостави своите умения и знания, за да създаде специализиран софтуер, който да отговаря на вашите уникални нужди.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      Кои са нашите успешни проекти?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <Link className='btn btn-border-base' to='/case-study-details'>
                        Натиснете тук! <FaPlus />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaTwo;
