import React from "react";
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const CustomSoftwareArea = () => {
    return (
        <>
            {/* ===================== Service Details Area start ===================== */}
            <div className='service-area pd-top-120 pd-bottom-90'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-12'>
                            <div className='td-sidebar service-sidebar'>
                                <div className='widget widget_catagory'>
                                    <h5 className='widget-title'>
                                        <FaArrowRight /> Всички услуги
                                    </h5>
                                    <ul className='catagory-items'>
                                    <li>
                                            <Link to='/mobiledev'> Изработка на мобилни приложения</Link>
                                        </li>
                                        <li>
                                            <Link to='/customsoftware'> Изработка на специализиран софтуер</Link>
                                        </li>
                                        <li>
                                            <Link to='/onlineshop'> Изработка на онлайн магазин</Link>
                                        </li>
                                        <li>
                                            <Link to='/webdesign'> Изработка на уеб дизайн</Link>
                                        </li>
                                        <li>
                                            <Link to='/seo'> SEO оптимизация</Link>
                                        </li>
                                        <li>
                                            <Link to='/support'> Техническа поддръжка</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='widget widget_author text-center'>
                                    <div className='thumb'>
                                        <img src='assets/img/professional-development-programmer-working-in-pro-2022-11-17-06-07-24-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <a className='btn btn-base border-radius-5' href='/about'>
                                            Научете повече за нас
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='blog-details-page-content'>
                                <div className='single-blog-inner mb-0'>
                                    <div className='thumb'>
                                        <img src='assets/img/programmer-and-ux-ui-designer-working-in-a-softwar-2022-11-11-19-03-30-utc.jpg' alt='img' />
                                    </div>
                                    <div className='details'>
                                        <h4>
                                            Имате нужда от помощ при изработката на специализиран софтуер?
                                        </h4>
                                        <p>
                                        Индивидуално създаденият софтуер – CMS, CRM или ERP е най-доброто решение за дейността и изпълнението на всяка една задача в процесите на нашите клиенти. 
                                        Решението с тези продукти е трайно и лесно за подръжка, позволява модулност и обновяване
                                            
                                        </p>
                                        <h4> Защо е важно да заложите на специализиран софтуер?</h4>
                                        <p>
                                        Специализираните софтуерни системи са завършени крайни продукти предлагащи цялостно обслужване в конкретна област. 
                                        Обикновено предлагат услуги на групи потребители, като потребителите могат да бъдат географски отдалечени и от различни организации. 
                                        Софтуерните системи имат два основни потребители: администратори, които настройват параметрите на системата, раздават права на потребителите, 
                                        нива на достъп, виртуални групи, обекти и характеристики към тях, права за ползване на услугите и потребители – 
                                        те използват системата по предназначението за което е създадена.


                                        Най-често срещаната необходимост от такива системи се наблюдава при бизнеси в които може да се постигне висока степен на оптимизация 
                                        и автоматизация на редица основни процеси.
                                        </p>
                                        <div
                                            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        Какви услуги предлагаме?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                    Изработването на сложна софтуерна система изисква вграждането на редица специализирани алгоритми в нея. 
                                                    При достигане на определено ниво на сложност се преминава към модулно изграждане на системата – тя се разделя на функционални модули,
                                                    който могат да работят самостоятелно и изграждането се извършва модул по модул.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        Колко време отнема да завършим даден проект?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Първоначална дисусия за уточняване на целите - 2 седмции <br />
                                                        Анализ - фазиране, бюджети, зависимости, модули, последователност - 1 месец <br />
                                                        Описание на решение - начин и реализация - 1 седмица <br />
                                                        Разработване на тестова среда - 2 месеца <br />
                                                        Тестове - 1 седмица <br />
                                                        Обратна връзка и корекции - 1 седмица <br />
                                                        Интеграция в продукшън среда - 3 дни <br />
                                                        Пускане в експлоатация - 2дни <br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        Колко струва разработката на специализиран софтуер?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body'>
                                                        Цената се определя спрямо време и ресурси!
                                                        Това е най-често използваният подход, при разработката на софтуер, особено ако става
                                                        въпрос за големи и сложни проекти. Специалистите определят цената, на база времето, което ще им 
                                                        отнеме за разработката на всеки един компонент и функционалност на апликацията.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===================== Service Details Area End ===================== */}
        </>
    );
};

export default CustomSoftwareArea;
