import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import serviceList from "../scripts/serviceList";

const ServiceAreaGroup = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>Нашите услуги</h6>
            <h2 className='title'>
              Ние сме вашият <span>партньор</span> в дигиталното развитие
            </h2>
          </div>
          <div className='row'>
            {serviceList.map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                    <img src={data.img} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      {data.title === 'Изработка на мобилни приложения' ? (
                        <Link to='/mobiledev'>{data.title}</Link>
                      ) : data.title === 'Специализиран софтуер' ? (
                        <Link to='/customsoftware'>{data.title}</Link>
                      ) : data.title === 'Изработка на онлайн магазин' ? (
                        <Link to='/onlineshop'>{data.title}</Link>
                      ) : data.title === 'Корпоративен уеб дизайн' ? (
                        <Link to='/webdesign'>{data.title}</Link>
                      ) : data.title === 'SEO оптимизация' ? (
                        <Link to='/seo'>{data.title}</Link>
                      ) : data.title === 'Техническа подръжка' ? (
                        <Link to='/support'>{data.title}</Link>
                      ) : (
                        <Link to='/service-details'>{data.title}</Link>
                      )}
                    </h5>
                    <p>{data.des}</p>
                    {data.title === 'Изработка на мобилни приложения' ? (
                      <Link className='btn btn-border-base' style={{marginBottom:"-95px"}} to='/mobiledev'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : data.title === 'Специализиран софтуер' ? (
                      <Link className='btn btn-border-base'  to='/customsoftware'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : data.title === 'Изработка на онлайн магазин' ? (
                      <Link className='btn btn-border-base' to='/onlineshop'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : data.title === 'Корпоративен уеб дизайн' ? (
                      <Link className='btn btn-border-base' to='/webdesign'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : data.title === 'SEO оптимизация' ? (
                      <Link className='btn btn-border-base' to='/seo'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : data.title === 'Техническа подръжка' ? (
                      <Link className='btn btn-border-base' to='/support'>
                        Научете повече <FaPlus />
                      </Link>
                    ) : (
                      <Link className='btn btn-border-base' to='/service-details'>
                        Научете повече <FaPlus />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
